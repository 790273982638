import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'gatsby';

import Logo from '../../images/logo.inline.svg';

import './Navigation.scss';

const Navigation = () => {
  return (
    <div className="component-navigation container">
      <Link to="/" className="navbar-brand component-navigation__navbar-brand">
        <Logo className="component-navigation__logo" />
      </Link>
    </div>
  );
};

export default Navigation;
