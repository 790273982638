import React from 'react'
import { Link } from 'gatsby'

import './Footer.scss'

const Footer = () => {
  return (
    <footer className="component-footer">
      <div className="component-footer__link-wrap">
        <Link to="/impressum/" className="component-footer__link">
          Impressum
        </Link>
        <Link to="/datenschutz/" className="component-footer__link">
          Datenschutz
        </Link>
        <a
          href="https://www.facebook.com/thomaslumpp.de"
          rel="noopener noreferrer"
          target="_blank"
          className="component-footer__link"
        >
          Facebook
        </a>
      </div>
      <div className="component-footer__copyright">© {new Date().getFullYear()} – Thomas Lumpp</div>
    </footer>
  )
}

export default Footer
