import React from 'react'

import Navigation from '../Navigation'

import './Header.scss'

const Header = () => (
  <header className="component-header">
    <Navigation />
  </header>
)

export default Header
